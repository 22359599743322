import { Avatar, Box, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'

export default function DashBoardCard({ title, subtitle, body, icon, isLoading }) {
    return (
        <Card
            elevation={10}
            sx={{
                cursor: "pointer",
                '&:hover': {
                    transition: "transform 0.15s ease-in-out",
                    transform: "scale3d(1.05, 1.05, 1)",
                },
            }}
        >
            <CardContent className="bg-slate-100 hover:bg-slate-200" >
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item xs={icon ? 6 : 12}>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                            noWrap={true}
                            sx={{
                                width: 1,
                                display: "block",
                            }}
                        >
                            {title}
                        </Typography>
                        {isLoading ?
                            <CircularProgress
                                size={22}
                                sx={{
                                    mx: 'auto',
                                    mt: 2,
                                }}
                            /> :
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {subtitle}
                            </Typography>
                        }
                    </Grid>
                    {icon &&
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                            }}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: 56,
                                    width: 56,
                                }}
                            >
                                {icon}
                            </Avatar>
                        </Grid>
                    }
                </Grid>
                {body &&
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            pt: 2
                        }}
                    >
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            {body}
                        </Typography>
                    </Box>
                }
            </CardContent>
        </Card>
    )
}