import * as React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Tab, Tabs, TextField } from '@mui/material'
import { Formik, Form, } from "formik"
import * as Yup from "yup"
import { db } from '../firebase'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import axios from 'axios'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import View from './View'
import uuid from 'react-uuid'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const fields = [
    { name: "name", type: "text", label: "Name" },
    { name: "from", type: "text", label: "From" },
    { name: "to", type: "text", label: "To" },
    { name: "bus", type: "text", label: "Bus name" },
    { name: "seatNumber", type: "text", label: "Seat Number" },
    { name: "fareAmount", type: "number", label: "Fare Amount" },
    { name: "agentNumber", type: "text", label: "Agent Number" },
];

const schema = Yup.object().shape(
    fields.reduce((obj, field) => {
        obj[field.name] = Yup.string().required(`${field.label} is required`)
        return obj
    }, {})
)

export default function FormDialog({
    openDialogForm,
    handleCloseDialogForm,
    action,
    selected,
    setSelected,
    ticketData,
    seenBy,
    totalTicket,
    setTicketData,
    pageView,
    setPageView,
}) {
    const handleSubmitPushNotification = async (userToken) => {
        try {
            const response = await axios.post(
                'https://fcm.googleapis.com/fcm/send',
                {
                    notification: {
                        title: 'Test notification',
                        body: "message",
                    },
                    to: userToken,
                },
                {
                    headers: {
                        Authorization: `key=${process.env.REACT_APP_MESSAGE_API_KEY}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Push notification sent:', response)
        } catch (error) {
            console.error('Error sending push notification:', error)
        }
    }

    const [arrivalTime, setArrivalTime] = React.useState(dayjs())

    const handleArrivalTime = (newValue) => {
        setArrivalTime(newValue);
    }

    const [depatcherTime, setDepatcherTime] = React.useState(dayjs())

    const handleDepatcherTime = (newValue) => {
        setDepatcherTime(newValue);
    }

    const handleChangePage = (event, value) => {
        setPageView(value)
    }

    return (
        <Dialog
            open={openDialogForm}
            onClose={handleCloseDialogForm}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
        >
            {action === "view" &&
                <View
                    page={pageView}
                    handleChangePage={handleChangePage}
                    selected={selected}
                    handleCloseDialogForm={handleCloseDialogForm}
                    totalTicket={totalTicket}
                />
            }
            {action !== "view" &&
                <Formik
                    enableReinitialize
                    initialValues={{ ...ticketData[0] }}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, }) => {
                        setSubmitting(true)
                        try {
                            const updatedPerson = {
                                ...values,
                                name: [...selected[0].name],
                                fareAmount: [...selected[0].fareAmount],
                                seatNumber: [...selected[0].seatNumber],
                                to: [...selected[0].to],
                                createAt: selected[0].createAt,
                                hasTicket: action === "generate" ? true : selected[0].hasTicket,
                                paid: selected[0].paid,
                                orderId: selected[0].orderId,
                                seenBy: seenBy,
                                hasCancelled: selected[0].hasCancelled,
                                date: selected[0].date,
                                numberOfSeats: selected[0].numberOfSeats,
                                serviceFee: selected[0].serviceFee,
                                moreInfo: selected[0].moreInfo,
                                customerPhoneNumber: selected[0].customerPhoneNumber,
                                servicePhoneNumber: "0763416291",
                                userUniqueness: selected[0].userUniqueness,
                                arrivalTime: action === "generate" ? arrivalTime.toISOString() : selected[0].arrivalTime,
                                depatcherTime: action === "generate" ? depatcherTime.toISOString() : selected[0].depatcherTime,
                            }

                            if (parseInt(selected[0].numberOfSeats) === (pageView + 1)) {
                                const ticketCollectionRef = doc(db, "ticket_request", selected[0].ticketId)
                                const q = query(collection(db, "users_message_token"), where("userUniqueness", "==", selected[0].userUniqueness))
                                const querySnapshot = await getDocs(q)
                                querySnapshot.forEach(async (doc) => {
                                    await setDoc(
                                        ticketCollectionRef,
                                        updatedPerson,
                                    )
                                    handleSubmitPushNotification(doc.data().token)
                                })

                                setSubmitting(false)
                                handleCloseDialogForm()
                            }
                            setSubmitting(false)
                        } catch (error) {
                            setSubmitting(false)
                        }
                    }}
                >
                    {({ isSubmitting, values, errors, touched, handleBlur, handleChange, resetForm }) => (
                        <Form
                            noValidate
                            autoComplete="off"
                        >
                            <DialogTitle>
                                {action === "generate" ? "Generate ticket" : "Edit ticket"}
                            </DialogTitle>
                            <DialogContent>
                                <Tabs
                                    value={pageView}
                                    onChange={(event, newValue) => {
                                        if (!Object.keys(errors).length) {
                                            const updatedArray = [
                                                ...selected.slice(0, 0),
                                                {
                                                    ...selected[0],
                                                    name: [
                                                        ...selected[0].name.slice(0, pageView),
                                                        values.name,
                                                        ...selected[0].name.slice(pageView + 1),
                                                    ],
                                                    fareAmount: [
                                                        ...selected[0].fareAmount.slice(0, pageView),
                                                        values.fareAmount,
                                                        ...selected[0].fareAmount.slice(pageView + 1),
                                                    ],
                                                    seatNumber: [
                                                        ...selected[0].seatNumber.slice(0, pageView),
                                                        values.seatNumber,
                                                        ...selected[0].seatNumber.slice(pageView + 1),
                                                    ],
                                                    to: [
                                                        ...selected[0].to.slice(0, pageView),
                                                        values.to,
                                                        ...selected[0].to.slice(pageView + 1),
                                                    ],
                                                    agentNumber: values.agentNumber,
                                                },
                                                ...selected.slice(0 + 1)
                                            ]
                                            console.log(updatedArray)
                                            resetForm()
                                            setSelected(updatedArray)
                                            const newData = [
                                                {
                                                    name: selected[0].name[newValue],
                                                    from: selected[0].from,
                                                    to: selected[0].to[newValue],
                                                    bus: selected[0].bus,
                                                    seatNumber: selected[0].seatNumber[newValue],
                                                    fareAmount: selected[0].fareAmount[newValue],
                                                    agentNumber: selected[0].agentNumber,
                                                },
                                            ]
                                            setTicketData(newData)
                                            setPageView(newValue)
                                        }
                                    }}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {totalTicket.map((item, index) => (
                                        <Tab
                                            key={uuid()}
                                            label={`Ticket ${index + 1}`}
                                        />
                                    ))}
                                </Tabs>
                                {selected.length === 1 && fields.map((field) => (
                                    <TextField
                                        name={field.name}
                                        type={field.type}
                                        label={field.label}
                                        margin="normal"
                                        fullWidth
                                        key={field.name}
                                        value={values[field.name]}
                                        error={Boolean(errors[field.name] && touched[field.name])}
                                        helperText={touched[field.name] && errors[field.name]}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                ))
                                }
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateTimePicker
                                        label="Arrival time"
                                        value={arrivalTime}
                                        onChange={handleArrivalTime}
                                        renderInput={
                                            (params) =>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    {...params}
                                                />
                                        }
                                    />
                                    <DateTimePicker
                                        label="Depatcher time"
                                        value={depatcherTime}
                                        onChange={handleDepatcherTime}
                                        renderInput={
                                            (params) =>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    {...params}
                                                />
                                        }
                                    />
                                </LocalizationProvider>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleCloseDialogForm}
                                >
                                    Cancel
                                </Button>
                                {action === "generate" &&
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Generate
                                    </Button>
                                }
                                {action === "edit" &&
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Edit
                                    </Button>
                                }
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            }
        </Dialog>
    )
}