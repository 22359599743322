import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
)

export default function GraphTicketSummary({ graphData }) {

    const theme = useTheme()

    const data = {
        datasets: [
            {
                backgroundColor: 'grey',
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: graphData.map((d) => d.value),
                label: 'Total',
                maxBarThickness: 10
            },
            {
                backgroundColor: '#179183',
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: graphData.map((d) => d.otherField1),
                label: 'Paid',
                maxBarThickness: 10
            },
            {
                backgroundColor: '#D1D5DB',
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: graphData.map((d) => d.otherField2),
                label: 'Not Paid',
                maxBarThickness: 10
            },
            {
                backgroundColor: 'rgba(255, 0, 0, 0.7)',
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: graphData.map((d) => d.otherField3),
                label: 'Cancelled',
                maxBarThickness: 10
            },
        ],
        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    }

    const options = {
        animation: true,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                }
            }
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    }

    return (
        <Card>
            <CardHeader
                action={(
                    <Button
                        size="small"
                    >
                        Last 7 days
                    </Button>
                )}
                title="Tickets Sales"
            />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 400,
                        position: 'relative'
                    }}
                >
                    <Bar
                        data={data}
                        options={options}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}