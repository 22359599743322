import { createBrowserRouter } from "react-router-dom"
import Home from "../pages/Home"
import TicketRequest from "../pages/TicketRequest"
import SignIn from '../sign-in/SignIn'
import Reports from "../pages/Reports"
import BusAgents from "../pages/BusAgents"
import Dashboard from "../dashboard/Dashboard"

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <Home />,
    },
    {
        path: "ticket-request",
        element: <Dashboard headerTitle='Ticket Requests'>
            <TicketRequest />
        </Dashboard>,
    },
    {
        path: "bus-agents",
        element: <BusAgents />,
    },
    {
        path: "reports",
        element: <Reports />,
    },
    {
        path: "login",
        element: <SignIn />,
    },
])