export const changePasswordFields = [
    { name: "current_password", type: "password", label: "Current Password" },
    { name: "new_password", type: "password", label: "New Password" },
    { name: "confirm_password", type: "password", label: "Repeat Password" },
]

export const registerAdminFields = [
    { name: "first_name", type: "text", label: "First Name" },
    { name: "last_name", type: "text", label: "Last Name" },
    { name: "phone_no", type: "text", label: "Phone Number" },
    {
        name: "gender",
        type: "select",
        label: "Gender",
        items: [
            { value: 'MALE', },
            { value: 'FEMALE', },
        ],
    },
    { name: "agent_bus_stand_name", type: "text", label: "Agent Bus Stand Name" },
    { name: "password", type: "password", label: "Password" },
]