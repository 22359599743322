import React from 'react'
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, MenuItem, OutlinedInput, Slide, TextField, Typography, } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from "yup"
import { DatePicker } from '@mui/x-date-pickers';
import { Input } from '@mui/icons-material';
import { CREATE } from '../utils/constants';
import { postRequest } from '../services/api-service';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function DialogForm({
    open,
    handleClose,
    dialogTitle,
    action,
    fields,
    values,
    url,
}) {
    const schema = Yup.object().shape(
        fields.reduce((obj, field) => {
            if (field.type === 'email') {
                obj[field.name] = Yup.string().email(`${field.label} should be email`)
                    .required(`${field.label} is required`)
            } else {
                obj[field.name] = Yup.string().min(1, `${field.label} minimum is one`)
                    .required(`${field.label} is required`)
            }
            return obj
        }, {})
    )
    const [serverError, setServerError] = React.useState("")

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="form-dialog"
            fullWidth={true}
            maxWidth={"md"}
        >
            <Formik
                initialValues={{ ...values[0] }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
                    // console.log("onSubmit", JSON.stringify(values, null, 2))
                    let body = values
                    for (let i = 0; i < fields.length; i++) {
                        if (fields[i].type === "date") {
                            body = { ...body, [fields[i].name]: values[fields[i].name].format("DD/MM/YYYY") }
                        }
                    }
                    postRequest(
                        url,
                        body,
                        setSubmitting,
                        (data) => {
                            resetForm()
                            setSubmitting(false)
                            handleClose()
                        },
                        (error) => {
                            if (error?.response?.data?.message) {
                                setServerError(error.response.data.message[0])
                            } else {
                                setErrors(error.response.data)
                            }
                            setSubmitting(false)
                        },
                        fields.some(item => item.type === "file") ? true : false
                    )
                }}
            >
                {({ isSubmitting, values, touched, errors, handleChange, handleBlur, setFieldValue, setValues }) => (
                    <Form
                        noValidate
                        autoComplete="off"
                    >
                        <DialogTitle>{`${action} ${dialogTitle}`}</DialogTitle>
                        <DialogContent>
                            {fields.map((field, index) => {

                                return (
                                    <React.Fragment key={index}>
                                        {field.type === "select" ?
                                            <TextField
                                                id={field.name}
                                                select
                                                margin='normal'
                                                label={field.label}
                                                placeholder={field.label}
                                                value={values[field.name]}
                                                error={Boolean(errors[field.name] && touched[field.name])}
                                                helperText={touched[field.name] && errors[field.name]}
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    setFieldValue(field.name, event.target.value)
                                                }}
                                                fullWidth
                                            >
                                                {field.items.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.value}
                                                    >
                                                        {item.value}
                                                    </MenuItem>
                                                ))}
                                            </TextField> :
                                            field.type === "file" ?
                                                <OutlinedInput
                                                    id={field.name}
                                                    placeholder={(values[field.name]?.name) || (values[field.name] !== null) ? "" : field.label}
                                                    readOnly
                                                    required
                                                    type="text"
                                                    margin="none"
                                                    fullWidth
                                                    error={Boolean(errors[field.name] && touched[field.name])}
                                                    startAdornment={(
                                                        <InputAdornment position="start">
                                                            {(values[field.name]?.name || values[field.name] !== null) &&
                                                                <Chip
                                                                    label={values[field.name]?.name ? values[field.name]?.name : "Image"}
                                                                    onDelete={() => {
                                                                        setValues({ ...values, [field.name]: null })
                                                                    }}
                                                                />
                                                            }
                                                        </InputAdornment>
                                                    )}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            {(values[field.name]?.name) || (values[field.name] !== null) ?
                                                                <Avatar
                                                                    alt={values[field.name]?.name}
                                                                    src={action === CREATE ? URL.createObjectURL(values[field.name]) : values[field.name]}
                                                                /> :
                                                                <IconButton
                                                                    aria-label="upload picture"
                                                                    component="label"
                                                                >
                                                                    <input
                                                                        hidden
                                                                        accept="image/*"
                                                                        type="file"
                                                                        onChange={(e) => {
                                                                            e.preventDefault();
                                                                            if (e.target.files) {
                                                                                setValues({ ...values, [field.name]: e.target.files[0] })
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Input />
                                                                </IconButton>
                                                            }
                                                        </InputAdornment>
                                                    }
                                                    sx={{ mt: 2 }}
                                                /> :
                                                field.type === "date" ?
                                                    <DatePicker
                                                        label={field.label}
                                                        value={values[field.name]}
                                                        onChange={(newValue) => {
                                                            setFieldValue(field.name, newValue)
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                margin: "normal",
                                                                error: Boolean(errors[field.name] && touched[field.name]),
                                                                helperText: touched[field.name] && errors[field.name],
                                                                onBlur: handleBlur,
                                                                fullWidth: true
                                                            }
                                                        }}
                                                    /> :
                                                    <TextField
                                                        id={field.name}
                                                        multiline
                                                        required
                                                        name={field.name}
                                                        type={field.type}
                                                        label={field.label}
                                                        margin="normal"
                                                        fullWidth
                                                        value={values[field.name]}
                                                        error={Boolean(errors[field.name] && touched[field.name])}
                                                        helperText={touched[field.name] && errors[field.name]}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                        }
                                    </React.Fragment>
                                )
                            })}
                            <Typography
                                color="error"
                                sx={{
                                    mt: 2,
                                }}
                            >
                                {serverError}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ?
                                    "Loading..." :
                                    `${action}`
                                }
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default DialogForm