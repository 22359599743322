import { Chip } from "@mui/material";

function renderStatus(status) {
    const colors = {
        YES: 'success',
        NO: 'error',
    };

    return <Chip label={status} color={colors[status]} size="small" />;
}

export const ticketRequestTableColumns = [
    {
        field: 'from',
        headerName: 'From',
        flex: 1,
        minWidth: 200
    },
    {
        field: 'to',
        headerName: 'To',
        flex: 1,
        minWidth: 200
    },
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        minWidth: 200
    },
    {
        field: 'bus',
        headerName: 'Bus Name',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'numberOfSeats',
        headerName: 'Total Seat',
        flex: 1,
        minWidth: 120,
    },
    {
        field: 'customerPhoneNumber',
        headerName: 'Phone',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'moreInfo',
        headerName: 'Description',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'paid',
        headerName: 'Paid',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => renderStatus(params.value ? 'YES' : 'NO'),
    },
    {
        field: 'createdAt',
        headerName: 'Ticket Request At',
        flex: 1,
        minWidth: 100,
    },
];