import * as React from 'react'
import { tableCellClasses } from '@mui/material/TableCell'
import { Box, Chip, CircularProgress, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popover, styled, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography, } from '@mui/material'
import { collection, query, onSnapshot, where, Timestamp } from "firebase/firestore"
import { db } from '../firebase'
import { EditOutlined, MoreVertOutlined, PrintOutlined, RemoveRedEyeOutlined, } from '@mui/icons-material'
import FormDialog from '../components/FormDialog'
import EnhancedTableHead from '../components/EnhancedTableHead'
import EnhancedTableToolbar from '../components/EnhancedTableToolbar'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { busTicketsStatus } from '../utils/constants'
import CustomTabs from '../components/CustomTabs'
import CustomizedDataGrid from '../dashboard/components/CustomizedDataGrid'
import { ticketRequestTableColumns } from '../utils/table-headers'
import dayjs from 'dayjs'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export default function TicketRequest({ noHeading }) {
  const [rows, setRows] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState("")
  const [ticketData, setTicketData] = React.useState([])
  const [totalTicket, setTotalTicket] = React.useState([])
  const [pageView, setPageView] = React.useState(0)
  const [filterBy, setFilterBy] = React.useState(3)
  const [isLoading, setIsLoading] = React.useState(false)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('createAt')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [action, setAction] = React.useState("")
  const [openDialogForm, setOpenDialogForm] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState(busTicketsStatus[0].value)
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  React.useEffect(() => {
    const getTickets = () => {
      const now = new Date()
      const yesterday = new Date(now)
      yesterday.setDate(now.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)
      const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
      const startOfDay = Timestamp.fromDate(now)
      const endOfDay = Timestamp.fromDate(new Date(yesterday.getTime() + 24 * 60 * 60 * 1000))
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const startOfDayToday = Timestamp.fromDate(today)
      const endOfDayToday = Timestamp.fromDate(new Date(today.getTime() + 24 * 60 * 60 * 1000))
      const ticketsQuery = filterBy === 0 ?
        query(collection(db, "ticket_request"), where("seenBy", "==", ""), where('createAt', '>=', startOfDayToday), where('createAt', '<', endOfDayToday)) :
        filterBy === 1 ?
          query(collection(db, "ticket_request"), where('createAt', '>=', startOfDay), where('createAt', '<', endOfDay)) :
          filterBy === 2 ?
            query(collection(db, "ticket_request"), where('createAt', '>=', sevenDaysAgo)) :
            query(collection(db, "ticket_request"), where("seenBy", ">=", ""))
      const unsubscribeTickets = onSnapshot(ticketsQuery, (querySnapshot) => {
        setIsLoading(true)
        const ticketsData = []
        querySnapshot.forEach((doc) => {
          ticketsData.push({
            ...doc.data(),
            ticketId: doc.id,
            id: doc.id,
            createdAt: dayjs(doc.data().createdAt.toDate()).format('MMM DD, YYYY HH:mm:ss')
          })
        })
        setRows(ticketsData)
        setIsLoading(false)
      })

      return () => {
        unsubscribeTickets()
      }
    }

    getTickets()
  }, [filterBy])

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClickPopOver = (event, selectedTicketRequestData) => {
    setAnchorEl(event.currentTarget)
    var newSelected = []
    newSelected.push(selectedTicketRequestData)
    setSelected(newSelected)
    const totalTicketsArray = Array.from({ length: parseInt(selectedTicketRequestData.numberOfSeats) }, (_, index) => index + 1)
    setTotalTicket(totalTicketsArray)
    const formData = [
      {
        name: selectedTicketRequestData?.name?.[0] || "",
        from: selectedTicketRequestData.from,
        to: selectedTicketRequestData.to[0],
        bus: selectedTicketRequestData.bus,
        seatNumber: selectedTicketRequestData?.seatNumber?.[0] || "",
        fareAmount: selectedTicketRequestData?.fareAmount?.[0] || "",
        agentNumber: selectedTicketRequestData.agentNumber,
      },
    ]
    setTicketData(formData)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClickOpenDialogForm = (actionToPerform) => {
    setAction(actionToPerform)
    setOpenDialogForm(true)
  }

  const handleCloseDialogForm = () => {
    setOpenDialogForm(false)
    setPageView(0)
  }

  const handleExport = () => {
    if (!isLoading) {
      if (rows.length >= 1) {
        const newRows = rows.map(row => {
          const { seenBy, userUniqueness, ticketId, orderId, createAt, arrivalTime, depatcherTime, ...newRow } = row
          newRow.createAt = new Date(createAt.toMillis()).toLocaleString()
          return newRow
        })

        const worksheet = XLSX.utils.json_to_sheet(newRows)

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Ticket Requests")

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })

        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })

        saveAs(blob, "ticket-requests.xlsx")
      }
    }
  }

  const handleClickFilterBy = (event, value) => {
    setFilterBy(value)
  }

  return (
    <>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Box
          sx={{
            width: '100%',
            px: !noHeading ? 3 : 0,
            pt: !noHeading ? 3 : 0,
            pb: !noHeading ? 8 : 0,
          }}
        >
          {openDialogForm &&
            <FormDialog
              openDialogForm={openDialogForm}
              handleCloseDialogForm={handleCloseDialogForm}
              action={action}
              selected={selected}
              setSelected={setSelected}
              ticketData={ticketData}
              totalTicket={totalTicket}
              setTicketData={setTicketData}
              pageView={pageView}
              setPageView={setPageView}
            />
          }
          {!noHeading ?
            <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
              Ticket Requests
            </Typography> :
            null
          }
          <CustomTabs
            tabs={busTicketsStatus}
            currentTab={currentTab}
            onClick={(item, index) => {
              setCurrentTab(item.value);
            }}
          />
          {/* <Paper sx={{ width: '100%', my: 2 }}>
            <EnhancedTableToolbar
              handleSearch={handleSearch}
              handleExport={handleExport}
              filterBy={filterBy}
              handleClickFilterBy={handleClickFilterBy}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .filter(item =>
                      Object.values(item).some(value =>
                        value
                          .toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.ticketId}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.from}
                          </StyledTableCell>
                          <StyledTableCell>{row.to}</StyledTableCell>
                          <StyledTableCell>{row.date}</StyledTableCell>
                          <StyledTableCell>{row.bus}</StyledTableCell>
                          <StyledTableCell align="right">{row.numberOfSeats}</StyledTableCell>
                          <StyledTableCell align="right">{row.customerPhoneNumber}</StyledTableCell>
                          <StyledTableCell>{row.moreInfo}</StyledTableCell>
                          <StyledTableCell>
                            <Chip
                              label={row.paid === true ? "Paid" : row.hasCancelled === true ? "Cancelled" : "Not paid"}
                              color={row.paid === true ? "success" : "error"}
                              variant="outlined"
                              sx={{ width: 90, }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              onClick={(event) => {
                                handleClickPopOver(event, row)
                              }}
                            >
                              <MoreVertOutlined />
                            </IconButton>
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              PaperProps={{
                                sx: { width: '200px' }
                              }}
                            >
                              <MenuList
                                disablePadding
                                sx={{
                                  '& > *': {
                                    '&:first-of-type': {
                                      borderTopColor: 'divider',
                                      borderTopStyle: 'solid',
                                      borderTopWidth: '1px'
                                    },
                                    padding: '12px 16px'
                                  }
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    selected[0]?.hasTicket === true ?
                                      handleClickOpenDialogForm("view", row) :
                                      handleClickOpenDialogForm("generate", row)
                                    handleClose()
                                  }}
                                >
                                  <ListItemIcon>
                                    {selected[0]?.hasTicket === true ?
                                      <RemoveRedEyeOutlined fontSize="small" /> :
                                      <PrintOutlined fontSize="small" />
                                    }
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    {selected[0]?.hasTicket === true ?
                                      "View ticket" :
                                      "Generate ticket"
                                    }
                                  </Typography>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleClickOpenDialogForm("edit", row)
                                    handleClose()
                                  }}
                                >
                                  <ListItemIcon>
                                    <EditOutlined fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Edit
                                  </Typography>
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </StyledTableCell>
                          <StyledTableCell>{row.createAt && row.createAt.toDate().toUTCString()}</StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading === true &&
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <CircularProgress
                  sx={{
                    mx: 'auto',
                    my: 3,
                  }}
                />
              </Box>
            }
            {rows.length === 0 && isLoading === false &&
              <Typography
                sx={{ my: 3, }}
                align='center'
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                No items
              </Typography>
            }
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> */}
          <CustomizedDataGrid
            loading={isLoading}
            columns={ticketRequestTableColumns}
            data={rows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        </Box>
      </Box>
    </>
  )
}
