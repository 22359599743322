//LIVE SERVER BASE URLs
export const baseUrl = "https://www.service.katatiketi.co.tz/api/v1"

//TEST SERVER BASE URLs
// export const baseUrl = "http://159.223.224.121:8000/api/v1"

//LOCAL SERVER BASE URLs
// export const baseUrl = "http://192.168.1.172:8000/api/v1"

export const registerUserUrl = `${baseUrl}/register/user`
export const loginUserUrl = `${baseUrl}/login/user`
export const getUserUrl = `${baseUrl}/get/user`
export const getUsersUrl = `${baseUrl}/get/users`
export const changePasswordUrl = `${baseUrl}/change/password`