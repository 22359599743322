import { Box, Typography } from '@mui/material'
import React from 'react'
import Dashboard from '../dashboard/Dashboard'

function Reports() {
  return (
    <Dashboard headerTitle='Reports'>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Reports
        </Typography>
      </Box>
    </Dashboard>
  )
}

export default Reports