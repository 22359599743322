import React from 'react'
import { Avatar, Box, Button, Card, Divider, Grid2, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material'
import DashBoardCard from '../components/DashBoardCard'
import TicketRequest from './TicketRequest'
import { AddOutlined, Menu, MoneyOutlined } from '@mui/icons-material'
import { CREATE } from '../utils/constants'
import { registerAdminFields } from '../utils/form-fields'
import DialogForm from '../components/DialogForm'
import { getUsersUrl, registerUserUrl } from '../services/urls'
import { postRequest } from '../services/api-service'
import Dashboard from '../dashboard/Dashboard'

function BusAgents() {
  const [busAgents, setBusAgents] = React.useState([])
  const [, setIsLoading] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState(1)
  const [openAgentsSideBar, setOpenAgentsSideBar] = React.useState(true)
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false)
  const values = [
    {
      first_name: "",
      last_name: "",
      phone_no: "",
      gender: "",
      role: "AGENT",
      agent_bus_stand_name: "",
      password: ""
    }
  ]

  const fetchBusAgents = React.useCallback(
    () => {
      postRequest(
        getUsersUrl,
        {
          role: "AGENT",
        },
        (data) => {
          setBusAgents(data.data)
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
        }
      )
    },
    []
  )

  const handleToogleAgentsSideBar = () => {
    setOpenAgentsSideBar(!openAgentsSideBar)
  }

  const handleTabChange = React.useCallback(
    (event, value) => {
      setCurrentTab(value)
    },
    []
  )

  const handleClickOpenCreateDialog = () => {
    setOpenCreateDialog(true)
  }

  const handleCloseCreateDialog = () => {
    fetchBusAgents()
    setOpenCreateDialog(false)
  }

  const adminItems = [
    {
      title: 'Total Request Attended',
      subtitle: 0,
      href: '/',
    },
    {
      title: 'Total Request Paid',
      subtitle: 0,
      href: '/',
    },
    {
      title: 'Total Request Not Paid',
      subtitle: 0,
      href: '/',
    },
    {
      title: 'T0tal Request Cancelled',
      subtitle: 0,
      href: '/',
    },
    {
      title: 'Total Amount Corrected',
      subtitle: 0,
      href: '/',
    },
    {
      title: 'Current Balance',
      subtitle: 0,
      href: '/',
    },
  ]

  React.useEffect(() => {
    fetchBusAgents()
  }, [fetchBusAgents])

  return (
    <Dashboard headerTitle='Bus Agents'>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        {openCreateDialog &&
          <DialogForm
            open={openCreateDialog}
            handleClose={handleCloseCreateDialog}
            dialogTitle={"Bus Agent"}
            action={CREATE}
            fields={registerAdminFields}
            values={values}
            url={registerUserUrl}
          />
        }
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Bus Agents
          </Typography>
          <div>
            <Button
              onClick={handleClickOpenCreateDialog}
              startIcon={(
                <AddOutlined />
              )}
              variant="contained"
              sx={{
                color: "neutral.100"
              }}
            >
              Add
            </Button>
          </div>
        </Stack>
        <Card variant='outlined'>
          <Grid2
            container
          >
            {openAgentsSideBar &&
              <Grid2
                size={{ xs: 12, sm: 12, lg: 3 }}
              >
                <Card
                  variant='outlined'
                  sx={{
                    minHeight: "100%",
                  }}
                >
                  <List sx={{ width: '100%', }}>
                    {busAgents.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ListItemButton alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar alt="John Doe" src="/static/images/avatar/1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${item.first_name} ${item.last_name}`}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {item.agent_bus_stand_name}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItemButton>
                          <Divider variant="inset" component="li" /></React.Fragment>
                      )
                    })}
                  </List>
                </Card>
              </Grid2>
            }
            <Grid2
              size={{ xs: 12, sm: 12, lg: openAgentsSideBar ? 9 : 12 }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <IconButton
                  onClick={handleToogleAgentsSideBar}
                  sx={{
                    mr: 3
                  }}
                >
                  <Menu fontSize="small" />
                </IconButton>
                <Tabs
                  onChange={handleTabChange}
                  value={currentTab}
                  variant='scrollable'
                  scrollButtons="auto"
                >
                  <Tab
                    label="Dashboard"
                    value={1}
                  />
                  <Tab
                    label="Attended Requests"
                    value={2}
                  />
                  <Tab
                    label="Checkout History"
                    value={3}
                  />
                </Tabs>
              </Box>
              <Divider />
              <Box
                sx={{
                  p: 4,
                }}
              >
                {currentTab === 1 &&
                  <Grid2
                    container
                    spacing={3}
                  >
                    {adminItems.map((item, index) => (
                      <Grid2
                        key={index}
                        size={{ xs: 12, sm: 12, lg: 4 }}
                        sx={{
                          '&:hover': {
                            color: "red",
                          },
                        }}
                      >
                        <DashBoardCard
                          title={item.title}
                          subtitle={item.subtitle}
                          isLoading={false}
                        />
                      </Grid2>
                    ))}
                  </Grid2>
                }
                {currentTab === 2 &&
                  <TicketRequest
                    noHeading={true}
                  />
                }
                {currentTab === 3 &&
                  <List sx={{ width: '100%', }}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>
                          <MoneyOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="TZS 35000/="
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Wednesday, 6th September 2023 07:43:21PM
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>
                          <MoneyOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="TZS 65000/="
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Wednesday, 4th September 2023 07:43:21PM
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                }
              </Box>
            </Grid2>
          </Grid2>
        </Card>
      </Box>
    </Dashboard>
  )
}

export default BusAgents