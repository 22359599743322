export const headers = {
    'katatiketi-sign-auth': `${process.env.REACT_APP_KEY}`,
}
export const contentTypeJson = {
    'Content-Type': `application/json`,
}
export const contentTypeFormData = {
    'Content-Type': `multipart/form-data`,
}
export const CREATE = "Create";
export const UPDATE = "Update";
export const busTicketsStatus = [
    { label: "All", value: "All" },
    { label: "Paid", value: "Paid" },
    { label: "Not Paid", value: "Not Paid" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Pending", value: "Pending" },
]