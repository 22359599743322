import React from 'react'
import { Button, DialogActions, DialogContent, Grid, Tab, Tabs, Typography } from '@mui/material'
import uuid from 'react-uuid'

function View({ page, handleChangePage, selected, handleCloseDialogForm, totalTicket }) {
    return (
        <>
            <DialogContent>
                <Typography variant="h5" component="h3">
                    Electronic Bus Ticket
                </Typography>
                <Tabs
                    value={page}
                    onChange={handleChangePage}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {totalTicket.map((item, index) => (
                        <Tab
                            key={uuid()}
                            label={`Ticket ${index + 1}`}
                        />
                    ))}
                </Tabs>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography>
                            Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {selected[0].name[page]}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            From:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {selected[0].from}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            To:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {selected[0].to}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            Seat Number:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {selected[0].seatNumber[page]}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            Arrival Time:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {selected[0].arrivalTime &&
                                Date(selected[0].arrivalTime)
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            Depatcher Time:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {selected[0].depatcherTime &&
                                Date(selected[0].depatcherTime)
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCloseDialogForm}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    )
}

export default View