import React from 'react'
import { Box, Grid2, Typography } from '@mui/material'
import uuid from 'react-uuid'
import { AirportShuttleOutlined, CancelOutlined, MoneyOffOutlined, PaidOutlined } from '@mui/icons-material'
import GraphTicketSummary from '../components/GraphTicketSummary'
import { collection, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore'
import { db } from '../firebase'
import Dashboard from '../dashboard/Dashboard'
import StatCard from '../dashboard/components/StatCard';

export default function Home() {
  const [rows, setRows] = React.useState([])
  const [graphData, setGraphData] = React.useState([])
  const [paid, setPaid] = React.useState(0)
  const [unpaid, setUnpaid] = React.useState(0)
  const [cancelled, setCancelled] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    setIsLoading(true)
    const now = new Date()
    const yesterday = new Date(now)
    yesterday.setDate(now.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const startOfDayToday = Timestamp.fromDate(today)
    const endOfDayToday = Timestamp.fromDate(new Date(today.getTime() + 24 * 60 * 60 * 1000))
    const dailyData = Array(7).fill().map(() => ({ value: [], otherField1: [], otherField2: [], otherField3: [] }))
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000
    const ticketsQuerySevenDays = query(collection(db, "ticket_request"), where('createAt', '>=', sevenDaysAgo), orderBy("createAt", "asc"))
    const ticketsQuery = query(collection(db, "ticket_request"), where('createAt', '>=', startOfDayToday), where('createAt', '<', endOfDayToday))
    const unsubscribeSevenDaysTickets = onSnapshot(ticketsQuerySevenDays, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const timestamp = doc.data().createAt.toDate()
        const value = doc.data().paid
        const otherField1 = doc.data().paid
        const otherField2 = doc.data().paid
        const otherField3 = doc.data().paid
        const dayOfWeek = new Date(timestamp - userTimezoneOffset).getDay()
        dailyData[dayOfWeek].value.push(value)
        if (doc.data().paid) {
          dailyData[dayOfWeek].otherField1.push(otherField1)
        }
        if (!doc.data().paid) {
          dailyData[dayOfWeek].otherField2.push(otherField2)
        }
        if (doc.data().hasCancelled) {
          dailyData[dayOfWeek].otherField3.push(otherField3)
        }
      })
      const groupedData = dailyData.map((dayData) => ({
        value: dayData.value.length,
        otherField1: dayData.otherField1.length,
        otherField2: dayData.otherField2.length,
        otherField3: dayData.otherField3.length,
      }))
      setGraphData(groupedData)
      setIsLoading(false)
    })

    const unsubscribeTickets = onSnapshot(ticketsQuery, (querySnapshot) => {
      const ticketsData = []
      querySnapshot.forEach((doc) => {
        ticketsData.push({ ...doc.data(), ticketId: doc.id })
      })
      setRows(ticketsData)
      const paid = ticketsData.filter(item => item.paid === true)
      const unpaid = ticketsData.filter(item => item.paid === false)
      const cancelled = ticketsData.filter(item => item.hasCancelled === true)
      setPaid(paid.length)
      setUnpaid(unpaid.length)
      setCancelled(cancelled.length)
      setIsLoading(false)
    })

    return () => {
      unsubscribeSevenDaysTickets()
      unsubscribeTickets()
    }
  }, [])

  const adminItems = [
    {
      title: 'T0tal',
      subtitle: rows.length,
      body: 'Today',
      icon: (<AirportShuttleOutlined />),
      href: '/',
    },
    {
      title: 'Paid',
      subtitle: paid,
      body: 'Today',
      icon: (<PaidOutlined />),
      href: '/',
    },
    {
      title: 'Not Paid',
      subtitle: unpaid,
      body: 'Today',
      icon: (<MoneyOffOutlined />),
      href: '/',
    },
    {
      title: 'Cancelled',
      subtitle: cancelled,
      body: 'Today',
      icon: (<CancelOutlined />),
      href: '/',
    },
  ];

  return (
    <Dashboard headerTitle='Home'>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Overview
        </Typography>
        <Grid2
          container
          spacing={3}
        >
          {adminItems.map((item) => (
            <Grid2
              key={uuid()}
              size={{ xs: 12, sm: 6, lg: 3 }}
              sx={{
                '&:hover': {
                  color: "red",
                },
              }}
            >
              <StatCard
                title={item.title}
                value={item.subtitle}
                interval={item.body}
                percentage={40}
                isLoading={isLoading}
              />
            </Grid2>
          ))}
          <Grid2
            size={{ xs: 12, sm: 12, lg: 12 }}
          >
            <GraphTicketSummary
              graphData={graphData}
            />
          </Grid2>
        </Grid2>
      </Box>
    </Dashboard>
  )
}